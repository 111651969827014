import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { reduxForm, Field, InjectedFormProps, initialize } from 'redux-form';
import ReduxFormInput from '../../components/shared/ReduxFormInput';
import ReduxFormDateTimePicker from '../../components/shared/ReduxFormDateTimePicker';
import ReduxFormAutocomplete from '../../components/shared/ReduxFormAutocomplete';
import ReduxFormAutocompleteMultiple from '../../components/shared/ReduxFormAutocompleteMultiple';
import { BOOKINGS_ADD_EDIT_FORM } from '../../utils/reduxFormConstants';
import { isPresent, isNilOrEmpty } from '../../utils/helper';
import { FormControlLabel } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';

interface BookingsDetailsFormProps {
  initialValues?: any;
  isDefault: boolean;
  customers: {}[];
  requestingPersons: {}[];
  bases: {}[];
  currentBookingData: any;
  passengers: {}[];
  equipments: {}[];
  pilot: {}[];
  ame: {}[];
  apprentice: {}[];
  aircraft: {}[];
  approvingClient: {}[];
  approvingCompany: {}[];
  isApprovalNeeded: any;
}

const BookingsDetailsForm = (props: any) => {
  const {
    initialValues,
    isDefault,
    currentBookingData,
    customers,
    requestingPersons,
    bases,
    passengers,
    equipments,
    pilot,
    ame,
    apprentice,
    aircraft,
    approvingClient,
    approvingCompany,
    isApprovalNeeded
  } = props;

  console.log(props, 'ALLProps');
  const dispatch = useDispatch();

  const minimumHoursTypeList = [
    {
      id: 'None',
      name: 'None'
    },
    {
      id: 'Daily',
      name: 'Daily'
    },
    // {
    //   id: 'Averaged per pay period',
    //   name: 'Averaged per pay period'
    // },
    {
      id: 'Averaged per booking',
      name: 'Averaged per booking'
    },
    {
      id: 'Averaged per year',
      name: 'Averaged per year'
    },
    {
      id: 'Pool',
      name: 'Pool'
    }
  ];

  const [isApprovedCheck, setIsApprovedCheck] = useState(false);
  console.log(currentBookingData.id, 'currentBookingData.id');
  useEffect(() => {
    if (isApprovalNeeded && isApprovalNeeded.length) {
      const currentIsApprovedData = isApprovalNeeded.find(
        (el: any) => el.id === currentBookingData.id
      );
      console.log(currentIsApprovedData, 'currentIsApprovedData');
      if (currentIsApprovedData !== undefined) {
        setIsApprovedCheck(currentIsApprovedData.value);
      }
    }
  }, []);

  useEffect(() => {
    console.log(initialValues, '@initialValues@');
    if (isPresent(initialValues)) {
      dispatch(initialize(BOOKINGS_ADD_EDIT_FORM, initialValues));
    }
  }, [dispatch, initialValues]);

  useEffect(() => {
    if (currentBookingData.id !== undefined) {
      dispatch({
        type: 'UPDATE_IS_APPROVAL_NEEDED',
        payload: {
          id: currentBookingData.id,
          flag: isApprovedCheck
        }
      });
    }
  }, [isApprovedCheck]);

  const handleChange = (event: any) => {
    setIsApprovedCheck(event.target.checked);
  };

  return (
    <div className="bookings-details-input-options">
      <div className="bookings-details-input-options-wrapper">
        <Field
          name="name"
          type="text"
          component={ReduxFormInput}
          label="Name"
          required
        />

        <Field
          name="customer"
          component={ReduxFormAutocomplete}
          label="Customer"
          optionsList={customers}
          disabled={isNilOrEmpty(customers)}
          required
        />

        <Field
          name="department"
          type="text"
          component={ReduxFormInput}
          label="Department"
        />
        <Field
          name="costCenter"
          type="text"
          component={ReduxFormInput}
          label="Cost Center"
        />
      </div>

      <div className="bookings-details-input-options-wrapper">
        <Field
          name="contract"
          type="text"
          component={ReduxFormInput}
          label="Contract"
        />
        <Field
          name="orderNumber"
          type="text"
          component={ReduxFormInput}
          label="Order Number"
        />

        <Field
          name="requestingPerson"
          component={ReduxFormAutocomplete}
          label="Requesting Person"
          disabled={isNilOrEmpty(requestingPersons)}
          optionsList={requestingPersons}
        />

        <Field
          name="base"
          component={ReduxFormAutocomplete}
          label="Base"
          disabled={isNilOrEmpty(bases)}
          optionsList={bases}
          required
        />
      </div>

      <div className="bookings-details-input-options-wrapper">
        <Field
          name="aircraft"
          component={ReduxFormAutocomplete}
          label="Aircraft"
          optionsList={aircraft}
          disabled={isDefault || isNilOrEmpty(aircraft)}
        />
      </div>

      <div className="bookings-details-input-options-wrapper">
        <Field
          name="startLocation"
          type="text"
          component={ReduxFormInput}
          label="Start Location"
          required
        />

        <Field
          name="startBooking"
          component={ReduxFormDateTimePicker}
          label="Start"
        />

        <Field
          name="meetingLocation"
          type="text"
          component={ReduxFormInput}
          label="Meet Location"
          required
        />

        <Field
          name="meetingDateTime"
          component={ReduxFormDateTimePicker}
          label="Meet"
        />
      </div>

      <div className="bookings-details-input-options-wrapper">
        <Field
          name="endLocation"
          type="text"
          component={ReduxFormInput}
          label="End Location"
          required
        />

        <Field
          name="endBooking"
          component={ReduxFormDateTimePicker}
          label="End"
        />

        <Field
          name="returnLocation"
          type="text"
          component={ReduxFormInput}
          label="Return Location"
          required
        />

        <Field
          name="returnDateTime"
          component={ReduxFormDateTimePicker}
          label="Return"
        />
      </div>

      <div style={{ border: '2px solid #2C6D8C' }}></div>
      <h2>Crew Minimum Flight Pay</h2>
      <div>
        <h3>Pilot:</h3>
        <div className="bookings-details-input-options-width-50">
          <Field
            id="pilot"
            name="pilot"
            component={ReduxFormAutocompleteMultiple}
            optionsList={pilot}
            variant="outlined"
            label="Pilot"
            disabled={isDefault || isNilOrEmpty(pilot)}
          />
        </div>
      </div>

      <div style={{ display: 'flex', gap: '150px' }}>
        <div id="mdd" className="bookings-details-input-options-width-50">
          <Field
            name="minimumHoursForPilot"
            id="minimumHoursForPilot"
            type="number"
            component={ReduxFormInput}
            label="Minimum Hours"
          />
        </div>

        <div
          style={{ width: '260px' }}
          id="mdd"
          className="bookings-details-input-options-width-50">
          <Field
            id="minHourTypeForPilot"
            name="minHourTypeForPilot"
            component={ReduxFormAutocomplete}
            optionsList={minimumHoursTypeList}
            variant="outlined"
            label="Minimum Hour Type"
            // disabled={isDefault || isNilOrEmpty(minimumHoursTypeList)}
          />
        </div>
      </div>
      <div>
        <h3>Ame:</h3>
        <div className="bookings-details-input-options-width-50">
          <Field
            id="ame"
            name="ame"
            component={ReduxFormAutocompleteMultiple}
            optionsList={ame}
            variant="outlined"
            label="AME"
            disabled={isDefault || isNilOrEmpty(ame)}
          />
        </div>

        <div style={{ display: 'flex', gap: '150px' }}>
          <div id="mdd" className="bookings-details-input-options-width-70">
            <Field
              id="minimumHoursForAme"
              name="minimumHoursForAme"
              type="number"
              component={ReduxFormInput}
              label="Minimum Hours"
            />
          </div>

          <div
            style={{ width: '260px' }}
            id="mdd"
            className="bookings-details-input-options-width-70">
            <Field
              id="minimumHourTypeForAme"
              name="minimumHourTypeForAme"
              component={ReduxFormAutocomplete}
              optionsList={minimumHoursTypeList}
              variant="outlined"
              label="Minimum Hour Type"
              disabled={isDefault || isNilOrEmpty(minimumHoursTypeList)}
            />
          </div>
        </div>
      </div>

      <div>
        <h3>Apprentice:</h3>
        <div className="bookings-details-input-options-width-50">
          <Field
            id="apprentice"
            name="apprentice"
            component={ReduxFormAutocompleteMultiple}
            optionsList={apprentice}
            variant="outlined"
            label="Apprentice"
            disabled={isDefault || isNilOrEmpty(apprentice)}
          />
        </div>
      </div>

      <div style={{ display: 'flex', gap: '150px' }}>
        <div className="bookings-details-input-options-width-50">
          <Field
            name="minimumHoursForApprentice"
            id="minimumHoursForApprentice"
            type="number"
            component={ReduxFormInput}
            label="Minimum Hours"
          />
        </div>

        <div
          style={{ width: '260px' }}
          className="bookings-details-input-options-width-50">
          <Field
            id="minimumHoursTypeForApprentice"
            name="minimumHoursTypeForApprentice"
            component={ReduxFormAutocomplete}
            optionsList={minimumHoursTypeList}
            variant="outlined"
            label="Minimum Hour Type"
            disabled={isDefault || isNilOrEmpty(minimumHoursTypeList)}
          />
        </div>
      </div>
      <div>
        <h3>Co-Pilot:</h3>
        <div className="bookings-details-input-options-width-50">
          <Field
            id="coPilot"
            name="coPilot"
            component={ReduxFormAutocompleteMultiple}
            optionsList={pilot}
            variant="outlined"
            label="Co-Pilot"
            disabled={isDefault || isNilOrEmpty(pilot)}
          />
        </div>
        <div style={{ display: 'flex', gap: '150px' }}>
          <div id="mdd" className="bookings-details-input-options-width-50">
            <Field
              name="minimumHoursCoPilot"
              type="number"
              component={ReduxFormInput}
              label="Minimum Hours"
            />
          </div>

          <div
            id="mdd"
            style={{ width: '260px' }}
            className="bookings-details-input-options-width-100">
            <Field
              id="minimumHourTypeCoPilot"
              name="minimumHourTypeCoPilot"
              component={ReduxFormAutocomplete}
              optionsList={minimumHoursTypeList}
              variant="outlined"
              label="Minimum Hour Type"
              disabled={isDefault || isNilOrEmpty(minimumHoursTypeList)}
            />
          </div>
        </div>
      </div>

      <h2>Client Minimum Flight Pay</h2>
      <div style={{ display: 'flex', gap: '150px' }}>
        <div className="bookings-details-input-options-width-50">
          <Field
            name="clientMinimumHours"
            id="clientMinimumHours"
            type="number"
            component={ReduxFormInput}
            label="Minimum Hours"
          />
        </div>

        <div
          style={{ width: '260px' }}
          className="bookings-details-input-options-width-50">
          <Field
            id="clientMinHourType"
            name="clientMinHourType"
            component={ReduxFormAutocomplete}
            optionsList={minimumHoursTypeList}
            variant="outlined"
            label="Minimum Hour Type"
            disabled={isDefault || isNilOrEmpty(minimumHoursTypeList)}
          />
        </div>
      </div>

      <div style={{ border: '2px solid #2C6D8C', marginBottom: '30px' }}></div>

      <div className="bookings-details-input-options-wrapper">
        <div className="bookings-details-input-options-width-50">
          <Field
            id="approvingClient"
            name="approvingClient"
            component={ReduxFormAutocompleteMultiple}
            optionsList={approvingClient}
            variant="outlined"
            label="Approving Client"
            disabled={isDefault || isNilOrEmpty(approvingClient)}
          />
        </div>

        <div className="bookings-details-input-options-width-50">
          <Field
            id="approvingCompany"
            name="approvingCompany"
            component={ReduxFormAutocompleteMultiple}
            optionsList={approvingCompany}
            variant="outlined"
            label="Approving Company"
            disabled={isDefault || isNilOrEmpty(approvingCompany)}
            // disabled={isDefault || isNilOrEmpty(client)}
            // disabled={true}
          />
        </div>
      </div>

      <div className="bookings-details-multi-selector">
        <Field
          id="passengers"
          name="passengers"
          component={ReduxFormAutocompleteMultiple}
          optionsList={passengers}
          variant="outlined"
          label="Passengers"
          disabled={isNilOrEmpty(passengers)}
        />
      </div>

      <div className="bookings-details-multi-selector">
        <Field
          id="equipments"
          name="equipments"
          component={ReduxFormAutocompleteMultiple}
          optionsList={equipments}
          variant="outlined"
          label="Equipments"
          disabled={isNilOrEmpty(equipments)}
        />
      </div>

      <div className="bookings-details-textarea">
        <Field
          name="notes"
          component={ReduxFormInput}
          rows={10}
          label="Notes"
          variant="outlined"
          multiline
        />
      </div>

      <div className="bookings-details-textarea">
        <FormControlLabel
          control={
            <Checkbox
              checked={isApprovedCheck}
              onChange={(e) => handleChange(e)}
              name="approvedFlightReport"
              color="primary"
            />
          }
          label="Approval of flight report required before showing on the client portal"
        />
      </div>
    </div>
  );
};

export default reduxForm<InjectedFormProps, BookingsDetailsFormProps>({
  form: BOOKINGS_ADD_EDIT_FORM
})(BookingsDetailsForm);
