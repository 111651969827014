import React, { Fragment, ReactNode, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Toolbar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DropdownButton from './DropdownButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import airOpsLogo from '../../assets/airops-logo.svg';
import {
  // navigationArray,
  initialNavigationArray,
  clientPortalRoutes,
  navigationArrayForLogBookAccess,
  navigationArrayForAircraftAccess,
  navigationArrayForBillingAccess,
  navigationArrayForBookingAccess,
  // navigationArrayForSupervisorsAccess,
  navigationArrayForCustomersAccess,
  navigationArrayForEmployeeAccess,
  navigationArrayForFlightreportAccess,
  navigationArrayForFuelAccess,
  navigationArrayForClientWebpageUser,
  navigationArrayForUserss,
  navigationArrayForJobsAccess,
  NavigationArrayProps,
  ROOT_ROUTE,
  HELP_ROUTE,
  CLIENT_PORTAL_ROUTE
} from '../../utils/routesNavigationConstants';
import { useFetchUserInfo, getCalculatedNavigationArray } from './hooks';
import { userLogOutRequest } from '../../redux/actions/userAuth';
import { isTokensPresentLocalStorage } from '../../services/utils/helper';

import './style.scss';
import * as R from 'ramda';
import StoreState from '../../redux/utils/interfaces';
// import * as R from 'ramda';

interface HeaderProps {
  secondaryHeader: ReactNode;
  navKey: string;
}
const Header = ({ secondaryHeader, navKey }: HeaderProps) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { userName } = useSelector((state: StoreState) => {
    return {
      userName:
        state.userInformation.firstName + ' ' + state.userInformation.lastName
    };
  });
  const goto = (route: string) => history.push(route);

  const {
    companyName,
    isApprovingSupervisor,
    isReviewingSupervisor,
    isLogbookWebpageUser,
    isJobsWebpageAccess,
    isWeeklyWebpageAccess,
    isAircraftWebpageAccess,
    isBillingWebpageAccess,
    isBookingWebpageAccess,
    isCustomersWebpageAccess,
    isEmployeesWebpageAccess,
    isFlightreportWebpageAccess,
    isFuelWebpageAccess,
    isClientUser,

    isClientWebpageAccess,
    isApproverAccess,
    isViewAdminAccess
  } = useFetchUserInfo();

  const handleUserLogout = () => {
    dispatch(userLogOutRequest());
  };

  const isLoggedIn = isTokensPresentLocalStorage();

  const restrictedNavigationArray = useMemo(() => {
    if (isClientUser) {
      return clientPortalRoutes;
    }
    let filteredArray = R.clone(initialNavigationArray);
    // if (isReviewingSupervisor || isApprovingSupervisor) {
    //   return navigationArray;
    // }
    if (isLogbookWebpageUser) {
      if (!R.find(R.propEq('key', 'logBook'))(filteredArray[4].options)) {
        filteredArray[4].options.push(navigationArrayForLogBookAccess);
      }
    }

    if (isWeeklyWebpageAccess) {
      if (!R.find(R.propEq('key', 'flightOps'))(filteredArray[1].options)) {
        filteredArray[0].options.push(navigationArrayForUserss);
      }
    }

    if (isJobsWebpageAccess) {
      if (
        !R.find(R.propEq('key', 'engineerJobReview'))(filteredArray[1].options)
      ) {
        filteredArray[1].options.push(navigationArrayForJobsAccess);
      }
    }

    if (isCustomersWebpageAccess) {
      if (!R.find(R.propEq('key', 'customers'))(filteredArray[3].options)) {
        filteredArray[3].options.push(navigationArrayForCustomersAccess);
      }
    }
    if (isBillingWebpageAccess) {
      if (!R.find(R.propEq('key', 'billing'))(filteredArray[2].options)) {
        filteredArray[2].options.push(navigationArrayForBillingAccess);
      }
    }
    if (isAircraftWebpageAccess) {
      if (!R.find(R.propEq('key', 'aircraft'))(filteredArray[3].options)) {
        filteredArray[3].options.push(navigationArrayForAircraftAccess);
      }
    }
    if (isBookingWebpageAccess) {
      if (!R.find(R.propEq('key', 'bookings'))(filteredArray[0].options)) {
        filteredArray[0].options.push(navigationArrayForBookingAccess);
      }
    }
    if (isEmployeesWebpageAccess) {
      if (!R.find(R.propEq('key', 'user'))(filteredArray[3].options)) {
        filteredArray[3].options.push(navigationArrayForEmployeeAccess);
      }
    }
    console.log(isFlightreportWebpageAccess, 'isFlightreportWebpageAccess');

    if (isFlightreportWebpageAccess) {
      if (!R.find(R.propEq('key', 'flightReport'))(filteredArray[0].options)) {
        filteredArray[0].options.push(navigationArrayForFlightreportAccess);
      }
    }
    if (isFlightreportWebpageAccess) {
      if (isApproverAccess || isViewAdminAccess) {
        if (
          !R.find(R.propEq('key', 'flightReport'))(filteredArray[0].options)
        ) {
          filteredArray[0].options.push(navigationArrayForFlightreportAccess);
        }
      }
    }

    if (isFuelWebpageAccess) {
      if (!R.find(R.propEq('key', 'fuelExpense'))(filteredArray[2].options)) {
        filteredArray[2].options.push(navigationArrayForFuelAccess);
      }
    }
    if (isClientWebpageAccess) {
      if (!R.find(R.propEq('key', 'clientUsers'))(filteredArray[3].options)) {
        filteredArray[3].options.push(navigationArrayForClientWebpageUser);
      }
    }
    // if(isApprovingSupervisor){
    //   if (!R.find(R.propEq('key', 'supervisor'))(filteredArray[3].options)) {
    //     filteredArray[3].options.push(navigationArrayForSupervisorsAccess,);
    //   }
    // }
    const navArray = getCalculatedNavigationArray(filteredArray);
    return navArray;
  }, [
    isApprovingSupervisor,
    isReviewingSupervisor,
    isLogbookWebpageUser,
    isJobsWebpageAccess,
    isAircraftWebpageAccess,
    isBillingWebpageAccess,
    isBookingWebpageAccess,
    isCustomersWebpageAccess,
    isApproverAccess,
    isEmployeesWebpageAccess,
    isFlightreportWebpageAccess,
    isFuelWebpageAccess,
    initialNavigationArray,
    isViewAdminAccess,
    isClientUser
  ]);

  return (
    <Fragment>
      <Toolbar className="custom-toolbar-1">
        <div className="logo-heading-container">
          <div
            className="logo-heading-wrapper"
            onClick={() =>
              goto(!isClientUser ? ROOT_ROUTE : CLIENT_PORTAL_ROUTE)
            }>
            <img src={airOpsLogo} alt="Logo" className="logo-image" />
            <div>
              <div className="company-header">
                <Typography variant="h6" component="h6">
                  AirOps
                </Typography>

                <Typography variant="caption" style={{ marginLeft: 10 }}>
                  {companyName}
                </Typography>
              </div>
              <Typography variant="caption">
                Flight Operations Made Easy
              </Typography>
            </div>
          </div>
        </div>
        {isLoggedIn && (
          <Fragment>
            <div className="nav-buttons-container">
              {restrictedNavigationArray.map(
                (navigation: NavigationArrayProps, index: number) => {
                  return (
                    <div key={index} className="header-dropdown-container">
                      <DropdownButton
                        navigation={navigation}
                        navKey={navKey}
                        goto={goto}
                      />
                    </div>
                  );
                }
              )}
            </div>
            <div className="logout-button-container">
              <Typography variant="body2">
                <button className="help-btn" onClick={() => goto(HELP_ROUTE)}>
                  Aide/Help
                </button>
                <br />
                {userName}
              </Typography>
              <button className="logout-button" onClick={handleUserLogout}>
                <ExitToAppIcon />
              </button>
            </div>
          </Fragment>
        )}
      </Toolbar>
      <Toolbar className="custom-toolbar-2">{secondaryHeader}</Toolbar>
    </Fragment>
  );
};

export default Header;
